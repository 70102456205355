import LocationsContext from "../contexts/LocationsContext";
import useDynamoLocations from "../hooks/UseDynamoLocations";

const LocationsProvider = ({ children }) => {
    const locations = useDynamoLocations();

    return (
        <LocationsContext.Provider value={{ locations }}>
            {children}
        </LocationsContext.Provider>
    );
}

export default LocationsProvider;