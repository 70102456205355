import React from "react";
import "../styles/frame.css"
import Users from "./Users";
import TeamButton from "./TeamButton";
import "../styles/team-button.css"
import "../styles/users.css"

function Frame() {
    return (
        <div className="frame">
            <Users />
            {/*<TeamButton />*/}
        </div>
  );
}

export default Frame;