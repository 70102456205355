import { useState } from "react";
import ViewContext from "../contexts/ViewContext";

const ViewProvider = ({ children }) => {
    const [focusedUser, setFocusedUser] = useState(null);
    const [viewComponent, setViewComponent] = useState(null);
    const [isTeamViewOpen, setIsTeamViewOpen] = useState(false);

    return (
        <ViewContext.Provider value={{ focusedUser, setFocusedUser, viewComponent, setViewComponent, isTeamViewOpen, setIsTeamViewOpen }}>
            {children}
        </ViewContext.Provider>
    );
}

export default ViewProvider;