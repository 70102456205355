import User from "./User";
import React, {useContext} from "react";
import UsersContext from "../contexts/UsersContext";


const Users = () => {
    let { users, loading } = useContext(UsersContext);

    if (loading) {
        return (
            <div className="frame">
                <div className="users-loading">
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    if (!users) {
        return (
            <div className="frame">
                <div className="users-empty">No users found</div>
            </div>
        );
    }

    return (
        <div className="users">
            {users.map((user) => (
                <User key={user.id} user={user}/>
            ))
        }
        </div>
    );
}

export default Users;