import { useState, useEffect } from 'react';
import AWS from "../awsConfig";
const useDynamoLocations = () => {
    const [locations, setLocations] = useState({});

    useEffect(() => {
        const fetchLocations = () => {
            const dynamodb = new AWS.DynamoDB.DocumentClient();

            const params = {
                TableName: 'location'
            };

            dynamodb.scan(params, (err, data) => {
                if (err) {
                    console.log(err);
                } else {
                    const locationMap = {};

                    data.Items.forEach((location) => {
                        locationMap[location.id] = location;
                    });

                    setLocations(locationMap);
                }
            });
        }

        fetchLocations();

        const intervalId = setInterval(fetchLocations, 60000);

        return () => clearInterval(intervalId);
    }, []);

    return locations;
}

export default useDynamoLocations;