function getTimeInTimeZone(timezone, timestamp) {
    const date = new Date(timestamp);
    const formatter = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone
    });
    return formatter.format(date);
}

export default getTimeInTimeZone;