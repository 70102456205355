import React, { useState, useEffect } from 'react';
import ElectronContext from '../contexts/ElectronContext';

const ElectronProvider = ({ children }) => {
    const [isElectron, setIsElectron] = useState(false);
    const [ipcRenderer, setIpcRenderer] = useState(null);

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf(' electron/') > -1) {
            try {
                console.log("App is electron - setting up IPC");
                setIsElectron(true);

                const { ipcRenderer } = window.require('electron');

                setIpcRenderer(ipcRenderer);
            } catch (e) {
                console.log("App is electron - but IPC not available");
            }
        } else {
            console.log("App is not electron - ICP not required");
        }
    }, []);

    const openUserDetails = () => {
        if (isElectron && ipcRenderer) {
            console.log("App is electron - sending IPC message to open user details");
            ipcRenderer.send('opened-user-details');
        } else {
            console.log("App is not electron - ICP not required");
        }
    };

    const closeUserDetails = () => {
        if (isElectron && ipcRenderer) {
            console.log("App is electron - sending IPC message to close user details");
            ipcRenderer.send('closed-user-details');
        } else {
            console.log("App is not electron - ICP not required");
        }
    };

    const openHuddleLink = (link) => {
        if (isElectron && ipcRenderer) {
            console.log("App is electron - sending IPC message to open huddle link");
            ipcRenderer.send('opened-huddle-link', link);
        } else {
            console.log("App is not electron - Opening with window");
            window.open(link, '_blank');
        }
    }

    return (
        <ElectronContext.Provider value={{ openUserDetails, closeUserDetails, openHuddleLink }}>
            {children}
        </ElectronContext.Provider>
    );
}

export default ElectronProvider;