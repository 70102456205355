import Frame from "./Frame";
import "../styles/side-bar.css"

const SideBar = () => {
    return (
        <div className="vertical_container">
            <Frame/>
        </div>
    );
}

export default SideBar;