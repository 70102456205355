import { useState, useEffect } from 'react';

const useAPIUsers = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const ts = new Date().getTime();

        const fetchUsers = () => {
            fetch('https://cdn.d47wgg8.com/users?'+ts, )
                .then(response => response.json())
                .then(json => {
                    setUsers(json.users);
                    setLoading(false);
                });
        };

        fetchUsers();

        const intervalId = setInterval(fetchUsers, 30000);

        return () => clearInterval(intervalId);
    }, []);

    return { users, loading };
}

export default useAPIUsers;