import "../styles/user-image-gallery.css";
import ImageGallery from 'react-image-gallery';

const UserImageGallery = ({ user }) => {
    const images = user.images.map((image) => {
        return {
            original: image.public_id,
        }
    });

    return (
        <div className="user_middle_container">
            <ImageGallery
                items={images}
                showPlayButton={false}
                showFullscreenButton={false}
                showThumbnails={false}
                showBullets={false}
                slideDuration={150}
            />
        </div>
    )
}

export default UserImageGallery;