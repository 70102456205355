import UsersContext from "../contexts/UsersContext";
import useDynamoUsers from "../hooks/UseDynamoUsers";
import useAPIUsers from "../hooks/UseAPIUsers";

const UsersProvider = ({ children }) => {
    const { users, loading } = useAPIUsers();

    return (
        <UsersContext.Provider value={{ users, loading }}>
            {children}
        </UsersContext.Provider>
    );
}

export default UsersProvider;