/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = () => {
    const env = process.env.REACT_APP_AUTH_ENV === "dev" ? "dev" : "prod";

    const config = {
        "aws_project_region": "us-west-2",
        "aws_cognito_identity_pool_id": "us-west-2:afda8f68-e4c6-4758-b473-f86380bd7010",
        "aws_cognito_region": "us-west-2",
        "aws_user_pools_id": "us-west-2_0DYlp09QL",
        "aws_user_pools_web_client_id": "5sflole9lg4l92llilqoib48l",
        "oauth": {
            "domain": "remotetogetherweb39f69ce7-39f69ce7-dev.auth.us-west-2.amazoncognito.com",
                "scope": [
                "email",
                "openid",
            ],
            "redirectSignIn": "http://localhost:3000/",
            "redirectSignOut": "http://localhost:3000/",
            "responseType": "code"
        },
            "federationTarget": "COGNITO_USER_POOLS",
            "aws_cognito_username_attributes": [
            "EMAIL"
        ],
            "aws_cognito_social_providers": [],
            "aws_cognito_signup_attributes": [
            "EMAIL"
        ],
            "aws_cognito_mfa_configuration": "OFF",
            "aws_cognito_mfa_types": [
            "SMS"
        ],
            "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
                "passwordPolicyCharacters": []
        },
            "aws_cognito_verification_mechanisms": [
            "PHONE_NUMBER"
        ]
    }

    if (env === "prod") {
        config.oauth.redirectSignIn = "https://remote-together-web.pages.dev/";
        config.oauth.redirectSignOut = "https://remote-together-web.pages.dev/";
    }

    return config;
};


export default awsmobile;
