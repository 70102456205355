import React from "react";
import "../styles/user.css"
import UserImage from "./UserImage";
import {useTimestamp} from "../hooks/UseTimestamp";
import getTimeInTimeZone from "../utils";
import UserImageGallery from "./UserImageGallery";

function User({ user }) {
    const timestamp = useTimestamp();

    const images = user.images;
    const currentImage = images[0];

    return (
      <div className="user" key={user.id}>
          <UserImageGallery user={user} />
          <div className="image-gradient">
            <span className="user-name image-text">{user.first_name}</span>
            <span className="user-time image-text">{getTimeInTimeZone(user.location.timezone, timestamp)}</span>
            <span className="user-location image-text">{currentImage.city}, {currentImage.short_state}</span>
            <span className="user-temp image-text">{Math.round(currentImage.temperature)}°</span>
          </div>
          {/*<span className="user-name image-text">{user.first_name}</span>*/}
          {/*<span className="user-time image-text">{getTimeInTimeZone(user.location.timezone, timestamp)}</span>*/}
          {/*<UserImage currentImage={currentImage} parentClass="user_middle_container" childClass="user_current_image" />*/}
          {/*<UserImageGallery user={user} />*/}
      </div>
  );
}

export default User;