import React from 'react';
import '../styles/app.css';
import MainView from "./MainView";
import SideBar from "./SideBar";
import ElectronProvider from "./ElectronProvider";
import UsersProvider from "./UsersProvider";
import LocationsProvider from "./LocationsProvider";
import ViewProvider from "./ViewProvider";

import {Amplify} from 'aws-amplify';
import awsConfig from '../aws-exports';

Amplify.configure(awsConfig());

function App() {
    return (
        <ElectronProvider>
            <UsersProvider>
                <LocationsProvider>
                    <ViewProvider>
                        <div className="app">
                            <SideBar/>
                        </div>
                    </ViewProvider>
                </LocationsProvider>
            </UsersProvider>
        </ElectronProvider>
    )
}

export default  App;
